import { get } from "lodash";

export default {
  props: {
    contentPath: String,
  },
  computed: {
    contentObject() {
      return this.getContent(this.contentPath);
    },
  },
  methods: {
    getContent(selectorPath) {
      const pathSplit = selectorPath.split("/");
      return pathSplit.reduce((coll, path) => {
        if (typeof coll === "string") {
          return coll;
        }
        return get(coll, path, selectorPath);
      }, this.$contentData);
    },
  },
};
