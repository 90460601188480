import { createApp } from "vue";
import App from "./App.vue";
import ContentPlugin from "./plugins/content-plugin";
import "./styles/index.scss";
import router from "./router";

createApp(App)
  .use(ContentPlugin)
  .use(router)
  .mount("#app");
