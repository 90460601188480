<template>
  <div class="textBlock" :class="justifyClass">
    <h1>{{ contentObject["headline"] }}</h1>
    <div class="space-small-p-tb  space-medium-m-t">
      <p class="text">{{ contentObject[`text`] }}</p>
    </div>
    <div class="flex-row space-medium-m-t">
      <Button
        class="center-on-small"
        v-for="button in contentObject['buttons']"
        :key="button['button-text']"
        :link="button['button-link']"
        :animated="true"
      >
        {{ button["button-text"] }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import ContentMixin from "../mixins/content-mixin";

const JUSTIFY_OPTIONS = {
  column: "flex-column fill center-start",
  row: "flex-row center-center"
}

export default {
  name: "TextBlock",
  mixins: [ContentMixin],
  props: {
    justify: {
      type: [Boolean, String],
      default: false,
      validator: (v) => Object.keys(JUSTIFY_OPTIONS).includes(v)
    }
  },
  components: {
    Button,
  },
  computed: {
    justifyClass(){
      if(!this.justify || !Object.keys(JUSTIFY_OPTIONS).includes(this.justify)) {
        return "";
      }
      return JUSTIFY_OPTIONS[this.justify];
    }
  }
};
</script>

<style lang="scss">
@import "../styles/00-definitions";

.textBlock {
  max-width: 40rem;
}
</style>
