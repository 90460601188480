import { get } from "lodash";
import contentData from "../assets/content.json";

export default {
  install: (app) => {
    app.config.globalProperties.$contentData = contentData;
    app.mixin({
      filter: {
        getContent(selectorPath) {
          const pathSplit = selectorPath.split("/");
          return pathSplit.reduce((coll, path) => {
            if (typeof coll === "string") {
              return coll;
            }
            return get(coll, path, selectorPath);
          }, contentData);
        },
      },
      methods: {
        getContent(selectorPath) {
          const pathSplit = selectorPath.split("/");
          return pathSplit.reduce((coll, path) => {
            if (typeof coll === "string") {
              return coll;
            }
            return get(coll, path, selectorPath);
          }, contentData);
        },
      },
    });
  },
};
