<template>
  <div>
    <a :href="link" :class="classes">
      <slot />
    </a>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    link: String,
    animated: Boolean,
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      let classes = "button";
      if (this.inverted) {
        classes += " inverted ";
      }
      if (this.animated) {
        classes += " button-animated";
        return classes;
      }
      classes += " button-text";
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/00-definitions.scss";

.button {
  transition: all 200ms;
  display: block;
  background: $color-white;
  border: 1.5px solid $color-black;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0.7rem $space-large;
  user-select: none;
  font-weight: bold;

  &.button-animated {
    border: 0;
    outline: 0;
    position: relative;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: all 200ms ease-out;
    &:after,
    &:before {
      content: " ";
      width: 10px;
      height: 10px;
      position: absolute;
      border: 0px solid transparent;
      transition: all ease-out 200ms;
    }
    &:after {
      top: -1px;
      left: -1px;
      border-top: 5px solid $color-blue;
      border-left: 5px solid $color-blue;
    }
    &:before {
      bottom: -1px;
      right: -1px;
      border-bottom: 5px solid $color-blue;
      border-right: 5px solid $color-blue;
    }
    &:hover {
      border-top-right-radius: 0px;
      border-bottom-left-radius: 0px;
      &:before,
      &:after {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.button:hover {
    text-decoration: none;
    background-color: $color-green;
    color: $color-white;
  }
  & a {
    text-decoration: none;
  }
  & a:link {
    color: $color-black;
  }
  & a:visited {
    color: $color-black;
  }
  & a:hover {
    color: $color-green;
  }
  & a:active {
    color: $color-green;
  }

  &.inverted {
    background: $color-blue;
    color: $color-white;
    border: 1.5px solid $color-white;
    &.button-animated {
      &:after {
        border-top: 5px solid $color-green;
        border-left: 5px solid $color-green;
      }
      &:before {
        border-bottom: 5px solid $color-green;
        border-right: 5px solid $color-green;
      }
    }
    &.button:hover {
      text-decoration: none;
      background-color: $color-green;
      color: $color-blue;
      &:after {
        border-top: 5px solid $color-blue;
        border-left: 5px solid $color-blue;
      }
      &:before {
        border-bottom: 5px solid $color-blue;
        border-right: 5px solid $color-blue;
      }
    }
  }
}
</style>
