<template>
  <div class="block flex-column">
    <h3 class="center-on-small">{{ contentObject["name"] }}</h3>

    <p class="text">{{ contentObject["description"] }}</p>
    <img
      class="center-on-small"
      :src="require('@/assets/' + contentObject['logo'])"
    />
  </div>
</template>
<script>
import ContentMixin from "../mixins/content-mixin";

export default {
  name: "PartnerBlock",
  mixins: [ContentMixin],
  components: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../styles/00-definitions";
@import "../styles/03-functions";
img {
  max-width: 25vw;
  @include breakpoint(tablet-down) {
      width: 50vw;
      max-width: 50vw;
      margin: auto;
  }
}
.block {
  max-width: 50%;
  width: 40%;
  padding: 1rem;
  @include breakpoint(tablet-down) {
      width: 75vw;
      max-width: 75vw;
  }
  @include breakpoint(mobile) {
      width: 100vw;
      max-width: 100vw;
  }
}
.center-on-small {
  @include breakpoint(tablet-down) {
    justify-content: center;
    align-items: center;
  }
}
</style>
