<template>
  <div class="bg space-medium-p-b flex-row">
    <img class="logo space-xlarge-p-lr space-xmedium-p-t" :src="logo" />
  </div>
</template>

<script>
import Logo from "../assets/logo.svg";
export default {
  name: "LogoBar",
  computed: {
    logo() {
      return Logo;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/styles/00-definitions.scss";

div {
  background-color: $color-yellow;
  width: 100%;
}

.logo {
  display: block;
  width: 100%;
}
</style>
