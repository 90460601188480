<template>
  <div class="header header-bar-fixed space-medium-p-m-l space-xlarge-p-l-l">
    <router-link to="/" class="home-image-container" >
      <img class="logo" :src="logo" />
    </router-link>
    <div class="spacer" />
    <div class="collapsible-menu routes">
      <template v-for="link in headerLinks" :key="link.id">
        <a
          v-if="isRelative(link['link']) || 1==2"
          :href="link['link']"
          class="link link-text"
        >
          {{ link["name"] }}
        </a>
        <router-link v-else :to="link['link']" class="link link-text">
          {{ link["name"] }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import Logo from "../assets/logo.svg";
export default {
  name: "HeaderBar",
  data() {
    return { showMenu: false };
  },
  computed: {
    logo() {
      return Logo;
    },
    headerLinks() {
      return this.$contentData["header-links"];
    },
  },
  methods: {
    isRelative(linkRef) {
      return String(linkRef).substring(0, 1) === "#";
    },
  },
};
</script>

<style lang="scss">
@import "../styles/00-definitions";
@import "../styles/03-functions";

.header {
  &.header-bar-fixed {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  height: 85px;
  @include breakpoint(mobile) {
    height: 50px;
  }
  display: flex;

  .home-image-container {
    display: block;
    & > img {
      width: 200px;
    }
    align-self: center;
  }
  .spacer {
    flex: auto;
  }
  .link {
    align-self: center;
    padding: 12px;
    text-decoration: none;
    color: #353535;
    min-width: 80px;
    text-align: left;

    //@include link-animation();

    &:visited {
      color: #353535;
    }
    &:hover {
      color: $color-green;
      text-decoration: underline;
    }
    &:active {
      color: #353535;
    }
    &:first-of-type {
      margin-left: 30px;
    }
    &:last-of-type {
      margin-right: 30px;
    }
  }
}
.routes {
  align-self: center;
}
.logo {
  display: block;
}
</style>
