<template>
  <div class="site flex-column start-center full-width">
    <HeaderBar id="#top" class="full-width header hide-on-mobile" />
    <LogoBar class="hide-on-desktop" />
    <!-- <div v-if="showBackToTop">
      <a href="#top">
        <img ref="backToTop" class="back-to-top" src="./assets/arrow.svg" />
      </a>
    </div> -->
    <router-view class="router-view" />

    <Footer
      id="kontakt"
      class="full-width space-medium-m-t"
      contentPath="footer"
    />
  </div>
</template>

<script>
import HeaderBar from "./components/HeaderBar.vue";
import LogoBar from "./components/LogoBar.vue"
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    HeaderBar,
    LogoBar,
    Footer,
  },
  data() {
    return {
      currentScrollPosition: 0,
    };
  },
  computed: {
    showBackToTop() {
      return this.currentScrollPosition > 80;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      try {
        this.currentScrollPosition = window.scrollY;
      } catch (e) {
        console.error("No window object available");
      }
    },
  },
};
</script>

<style lang="scss">
@import "./styles/00-definitions";
@import "./styles/03-functions";

.site {
  min-height: 95vh;
  padding-top: 85px;
  @include breakpoint(mobile) {
    padding-top: 0px;
  }
}

.back-to-top {
  opacity: 1;
  display: block;
  position: fixed;
  top: 10px;
  left: 25px;
  z-index: 99;
  cursor: pointer;
  padding: 5px;
  font-size: 18px;
  width: 35px;
  height: 35px;
  animation-name: fly-in, bounce;
  animation-duration: 1s, 2s;
  animation-direction: normal, alternate;
  animation-delay: 0s, 1s;
  animation-timing-function: cubic-bezier(), cubic-bezier();
  animation-iteration-count: 1, infinite;
  @include breakpoint(mobile) {
    display: none;
  }
}
</style>
