<template>
  <div class="container">
    <div class="image-container">
      <img
        class="full-width circle-clip"
        :src="require('@/assets/member/' + member['image-src'])"
      />
    </div>
    <div class="subtext">
      <p class="member-name">{{ member["name"] }}</p>
      <p class="member-position">{{ member["position"] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamItem",
  props: {
    member: Object,
  },
};
</script>

<style scoped lang="scss">
@import "../styles/00-definitions";
@import "../styles/03-functions";

.container {
  width: 32%;
  min-height: 200px;
  @include breakpoint(mobile) {
    width: 49.5%;
    min-height: 200px;
    padding-bottom: $space-xmedium;
  }
}
.image-container {
  width: 100px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
}
.circle-clip {
  clip-path: circle(50px at center);
}
.subtext {
  text-align: center;
}
.member-name {
  font-family: Raleway-Bold;
  font-size: 0.938rem;
  line-height: 170%;
  letter-spacing: 0.5px;
}
.member-position {
  font-family: Raleway-Regular;
  font-size: 0.938rem;
  line-height: 170%;
  letter-spacing: 0.5px;
}
</style>
