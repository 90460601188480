export default function redirects(){
    return [
        {
            path: "/solution-enabler",
            name: "Solution Enabler",
            link: "https://wirvsvirus.org/solution-enabler/",
        },
        {
            path: "/call4transfer",
            name: "Call for transfer",
            link: "https://hamburginnovation.de/projekte/calls-for-transfer/",
        },
        {
            path: "/science-slam",
            name: "Science Slam 2023",
            link: "https://www.youtube.com/watch?v=MoO_h6okupU",
        },
        {
            path: "/gesellschaft-informatik",
            name: "Gesellschaft Informatik",
            link: "https://gi.de/meldung/von-empathischer-ki-bis-green-it-vier-informatiktalente-als-gi-junior-fellows-ausgezeichnet",
        },
        {
            path: "/research-gate",
            name: "Research Gate",
            link: "https://www.researchgate.net/profile/Larissa-Gebken",
        },
    ]
}