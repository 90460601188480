<template>
  <div class="full-width block-center">
    <div class="block-center" v-if="contentObject['image-src']">
      <img class="match-parent" :src="require('@/assets/illu/' + contentObject['image-src'])" />
    </div>
    <p class="text-small block-center" v-html="contentObject['text']"></p>
    <div class="block-center" v-if="contentObject['source-link'] != null">
      <a
        :href="contentObject['source-link']"
        v-html="contentObject['source-text']"
      />
    </div>
  </div>
</template>

<script>
import ContentMixin from "../mixins/content-mixin";

export default {
  name: "SliderCard",
  mixins: [ContentMixin],
};
</script>

<style scoped>
.match-parent {
  width: 100%;
  margin: auto;
  overflow: auto;
  max-width: 300px;
}
</style>
