<template>
  <div class="flex-row">
    <TeamItem
      class="item"
      v-for="member in members"
      :key="member.id"
      :member="member"
    />
  </div>
</template>

<script>
import TeamItem from "./TeamItem.vue";
export default {
  name: "TeamBlock",
  components: {
    TeamItem,
  },
  props: {
    members: Array,
  },
};
</script>

<style>
.item {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  
  -webkit-transition: -webkit-transform 1s, -webkit-filter 1s;
  -moz-transition: -moz-transform 1s, -moz-filter 1s;
  -o-transition: -o-transform 1s, -o-filter 1s;
  transition: transform 1s, filter 1s;
}
.item:hover {
   -webkit-transform: scale(110%);
  -moz-transform: scale(110%);
  -o-transform: scale(110%);
  transform: scale(110%);
   -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  filter: grayscale(0%);
}
</style>
