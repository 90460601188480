import { createRouter, createWebHistory } from "vue-router";
import Main from "../views/Main.vue";
import redirects from "../router/redirects"

const routes = [
  {
    path: "/",
    name: "OpenStreetPAY",
    component: Main,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () =>
      import(/* webpackChunkName: "challenge" */ "../views/Impressum.vue"),
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: () =>
      import(/* webpackChunkName: "idee" */ "../views/Datenschutz.vue"),
  },
  // {
  //   path: "/solution-enabler",
  //   name: "Solution-Enabler",
  //   component: () =>
  //     import(/* webpackChunkName: "kontakt" */ "../views/Impressum.vue"),
	//   beforeEnter(to, from, next) {
  //     window.location.href = "https://google.com/contact";
  //   },
  // },
  // {
  //   path: "/science-slam",
  //   name: "",
  //   component: () =>
  //     import(/* webpackChunkName: "kontakt" */ "../views/Impressum.vue"),
  //   beforeEnter(to, from, next) {
  //     window.location.href = "https://google.com/contact";
  //   },
  // },
  // {
  //   path: "/gesellschaft-fuer-informatik",
  //   name: "GInformatik",
  //   component: () =>
  //     import(/* webpackChunkName: "kontakt" */ "../views/Impressum.vue"),
  //   beforeEnter(to, from, next) {
  //     window.location.href = "https://google.com/contact";
  //   },
  // },
  // {
  //   path: "/call4transfer",
  //   name: "Call4Transfer",
  //   component: () =>
  //     import(/* webpackChunkName: "kontakt" */ "../views/Impressum.vue"),
  //   beforeEnter(to, from, next) {
  //     window.location.href = "https://google.com/contact";
  //   },
  // },
  // {
  //   path: "/",
  //   name: "anderer",
  //   component: () =>
  //     import(/* webpackChunkName: "kontakt" */ "../views/Impressum.vue"),
  //   beforeEnter(to, from, next) {
  //     window.location.href = "https://google.com/contact";
  //   },
  // },
];

redirects().forEach(redirect => routes.push(
    {
      path: redirect["path"],
      name: redirect["name"],
      beforeEnter() {
        window.location.href = redirect["link"]
      },
    }
  )
)

console.log(routes)

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function(to) {
    if (to.hash) {
        try {
          setTimeout(() => {
            const elem = document.getElementById(to.hash.replace("#",""));
            elem.scrollIntoView();
          }, 10);
        //eslint-disable-next-line no-empty
        } catch(_) {}
    }
    return { x: 0, y: 0 }
},
});

export default router;
