<template>
  <div class="auto-slider box block-center">
    <div class="full-width center-text">
      <p class="roof-title">{{ contentObject["roof-title"] }}</p>
      <div class="line space-small-m-b" />
      <div class="subline-container">
        <h2>{{ contentObject["headline"] }}</h2>
      </div>
    </div>
    <div class="card block-center">
      <carousel :items-to-show="1">
        <slide
          v-for="(slide, index) in contentObject['slides']"
          :key="slide['index']"
        >
          <AutoSliderCard
            class="space-xmedium-p-l space-xmedium-p-r"
            :contentPath="`${contentPath}/slides/${index}`"
          />
        </slide>
        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import AutoSliderCard from "./AutoSliderCard";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import ContentMixin from "../mixins/content-mixin";

export default {
  name: "AutoSlider",
  mixins: [ContentMixin],
  components: {
    AutoSliderCard,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>

<style lang="scss">
@import "../styles/00-definitions";
@import "../styles/03-functions";

.auto-slider {
  .card {
    max-width: 100%;
    background: $color-white;
    box-shadow: $shadow-xl;
    border-radius: 30px 50px 50px 50px;

    @include breakpoint(tablet-down) {
      padding: 40px 0;
    }
    
    @include breakpoint(desktop) {
      padding: 40px;
    }
  }
  .line {
    margin-left: auto;
    margin-right: auto;
    width: 1.5rem;
    height: 1px;
    transition: width 2s;
    background: #353535;
  }
  .line {
    width: 3rem;
  }
  .center-text {
    text-align: center;
  }
  .carousel {
    @include breakpoint(tablet-down) {
      width:85vw;
    }
    .carousel__next,
    .carousel__prev {
      background-color: $color-blue;
    }
    .carousel__pagination-button {
      background-color: fade-out($color-blue, 0.7);
      &.carousel__pagination-button--active {
        background-color: $color-blue;
      }
    }
  }
}
</style>
