<template>
  <div class="bgcolor-yellow">
    <div class="flex-row center-center max-width">
      <InlineTextBlock
        v-for="(block, i) in contentObject['contact-blocks']"
        v-bind:key="block['headline']"
        :contentPath="`${contentPath}/contact-blocks/${i}`"
      />
      <div class="flex-row full-width center-center">
        <router-link
          v-for="link in contentObject['footer-links']"
          v-bind:key="link.id"
          :to="link['link']"
          class="link link-text space-medium-p-all"
        >
          {{ link["name"] }}
        </router-link>
        <a
          href="https://gitlab.itologie.de/openstreetpay"
          class="link link-text space-medium-p-all"
        >
          Unsere Codebase&nbsp;
          <img
            src="../assets/link_external.svg"
            style="height: 16px; width: 16px"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import InlineTextBlock from "./InlineTextBlock.vue";
import ContentMixin from "../mixins/content-mixin";

export default {
  name: "Footer",
  mixins: [ContentMixin],
  components: {
    InlineTextBlock,
  },
};
</script>

<style></style>
