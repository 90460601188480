<template>
  <section id="mainPage">
    <div class="absolute">
      <!-- Intro section -->
      <section class="intro-container flex-row spaced full-width">
        <div
          class="small-change-container two-third-width flexed spaced flex-row bgcolor-yellow space-s-medium-p-t space-s-medium-p-b space-s-medium-p-lr space-l-xxlarge-p-t space-l-xlarge-p-b space-l-xlarge-p-lr"
        >
          <div
            class="small-change full-width-desktop full-width-tablet full-width-mobile space-small-s-p-all space-medium-p-m-l space-xlarge-p-l-l space-xxlarge-p-r"
          >
            <TextBlock
              class="space-medium-p-tb full-width-mobile"
              contentPath="welcome-block"
              justify="column"
            />
          </div>
        </div>
        <div class="third-width relative-illustration hide-on-small">
          <img
            class="img--top-illustration"
            src="../assets/Starter_Mobile_1.svg"
          />
        </div>
        <div
          class="green-checkered-bar two-third-width full-width-mobile"
        ></div>
        <div class="blue-bar bgcolor-blue third-width hide-on-small"></div>
      </section>
      <!-- Challenge section -->
      <section
        id="challenge"
        class="main-section full-width max-width challenges-container flex-row spaced space-small-m-t"
      >
        <div class="wirvsvirus-container flex-column block-center">
          <img
            id="illu-idea"
            class="img--idea-illustration block-center"
            src="../assets/illu/Illustration_1_OPS.svg"
          />
          <InlineTextBlock contentPath="idea-block" :isLeft="true" />
        </div>

        <AutoSlider
          class="flex-column block-center half-width-desktop space-medium-m-t"
          contentPath="challenge-slider"
        />
      </section>
      <!-- Idea section -->
      <section
        id="idee"
        class="main-section full-width max-width flex-column block-center"
      >
        <InlineTextBlock
          class="block--idea-communication space-small-m-tb"
          v-for="(block, index) in content['info-blocks']"
          v-bind:key="block['headline']"
          :contentPath="`info-blocks/${index}`"
          :isRight="index % 2 == 1"
        />
      </section>
      <!-- Team section -->
      <section id="team">
        <div
          class="main-section full-width max-width ueberuns-container flex-row spaced space-medium-m-tb max-width"
        >
          <AutoSlider
            class="flex-column block-center half-width-desktop"
            contentPath="code-of-conduct-slider"
          />
          <div
            class="full-width flex-column block-center half-width-desktop space-large-m-t p-l-on-desktop"
          >
            <img
              id="illu-sm"
              class="block-center img--idea-design-faculty"
              src="../assets/illu/Illustration_7_OPS.svg"
            />
            <InlineTextBlock contentPath="team-block" />
          </div>
        </div>
        <TeamBlock
          id="team-block"
          :members="content['team-members']"
          class="full-width max-width sec--team-block space-large-m-t block-center"
        />
      </section>
      <!-- Partner section -->
      <section id="partner">
        <div class="full-width flex-column block-center main-section">
          <h1 class="center-center max-width space-medium-m-tb">
            {{ content["partner-block"]["headline"] }}
          </h1>
          <div class="max-width spaced block--partner-content">
            <PartnerBlock
              class="spaced"
              v-for="(block, index) in content['partner-block']['partners']"
              v-bind:key="block['name']"
              :contentPath="`partner-block/partners/${index}`"
            />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
import TextBlock from "../components/TextBlock.vue";
import InlineTextBlock from "../components/InlineTextBlock.vue";
import AutoSlider from "../components/AutoSlider.vue";
import TeamBlock from "../components/TeamBlock.vue";
import PartnerBlock from "../components/PartnerBlock.vue";

import Content from "../assets/content.json";

export default {
  name: "page-main",
  components: {
    TextBlock,
    InlineTextBlock,
    AutoSlider,
    TeamBlock,
    PartnerBlock,
  },
  data() {
    return {
      showBackToTop: Boolean,
    };
  },
  computed: {
    content: () => Content,
  },
  mounted() {
    onload = () => {
      this.showBackToTop = window.scrollY > 60;
    };
    onscroll = () => {
      this.showBackToTop = window.scrollY > 60;
    };
  },
};
</script>

<style lang="scss">
@import "../styles/00-definitions";
@import "../styles/03-functions";

.img--top-illustration {
  position: relative;
  left: -10%;
  top: 5%;
  z-index: 2;
}

.img--idea-illustration {
  width: 400px;
  @include breakpoint(mobile) {
    width: 250px;
  }
}

.img--idea-design-faculty {
  width: 300px;
  @include breakpoint(mobile) {
    width: 200px;
  }
}

.blue-bar {
  position: relative;
  height: 80px;
  top: -16px;
  transform: scaleY(1.4);
}

.green-checkered-bar {
  height: 80px;
  background: repeating-linear-gradient(
    230deg,
    #fff 0px,
    #fff 25px,
    #30d0a9 25px,
    #30d0a9 50px
  );
  background-repeat: repeat-x;
}

.sec--team-block {
  max-width: 1000px;
}

.full-width-mobile {
  @include breakpoint(mobile) {
    width: 100vw;
  }
}

.p-l-on-desktop {
  padding-left: $space-large;
  @include breakpoint(tablet-down) {
    padding-left: 0px;
  }
}

.block--partner-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  flex-direction: row;
  @include breakpoint(tablet-down) {
    flex-direction: column;
  }
}

.main-section {
  margin-top: $space-xlarge;
  padding-top: $space-xlarge;
  @include breakpoint(mobile) {
    margin-top: $space-medium;
    padding-top: $space-medium;
  }
  // &:before {
  //   content: "";
  //   display: block;
  //   transition: all 400ms;
  //   width: 65%;
  //   margin-left: 35%;
  //   height: 0.2rem;
  //   background: $color-green;
  //   margin-top: $space-xlarge;
  //   margin-bottom: $space-large;
  // }
  // &:hover {
  //   &:before {
  //     width: 75%;
  //     margin-left: 25%;
  //   }
  // }
  // &:nth-of-type(odd) {
  //   &:before {
  //     margin-left: 0%;
  //   }
  //   &:hover {
  //     &:before {
  //       margin-left: 0%;
  //     }
  //   }
  // }
}
</style>
