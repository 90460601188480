<template>
  <div
    class="main-container center-center"
    :class="[hasImage ? 'flex-row' : '', isRight ? 'set-right' : '']"
  >
    <div v-if="hasImage" class="flex-row space-medium-p-all">
      <transition name="filter-in">
        <img
          ref="image"
          class="center-center"
          :class="animateIn ? 'clear' : 'grey'"
          :src="require('@/assets/illu/' + contentObject['image-src'])"
        />
      </transition>
    </div>
    <div class="text-container">
      <p class="roof-title">{{ contentObject["roof-title"] }}</p>
      <div class="line space-small-m-b" />
      <div>
        <h2 v-html="spannedHeadline"></h2>
      </div>
      <div>
        <p class="text">{{ contentObject["text"] }}</p>
      </div>
      <div class="flex-row space-xmedium-m-t center-on-small">
        <Button
          class="space-xmedium-m-r"
          v-for="button in contentObject['buttons']"
          v-bind:key="button['button-text']"
          :link="button['button-link']"
          :animated="true"
          :inverted="true"
        >
          {{ button["button-text"] }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "./Button.vue";
import ContentMixin from "../mixins/content-mixin";

export default {
  name: "InlineTextBlock",
  mixins: [ContentMixin],
  components: {
    Button,
  },
  props: {
    isRight: Boolean,
  },
  mounted() {
    document.addEventListener("scroll", this.scroll);
    this.animateIn = false;
  },
  data() {
    return {
      animateIn: Boolean,
    };
  },
  methods: {
    scroll() {
      if (
        this.$refs.image == null ||
        this.$refs.image == undefined ||
        this.animateIn
      )
        return;
      if (
        this.$refs.image.offsetTop - this.$refs.image.height * 1.8 <
        window.scrollY
      ) {
        console.log("scrolled over me");
        this.animateIn = true;
      }
    },
  },
  computed: {
    spannedHeadline() {
      return this.contentObject["headline"]
        .replace("[", '<span class="h3-thinline">')
        .replace("]", "</span>");
    },
    imageSrc() {
      return this.contentObject["image-src"] || null;
    },
    hasImage() {
      return this.imageSrc !== null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../styles/00-definitions";
@import "../styles/03-functions";

.main-container .line {
  width: 50px;
  height: 1px;
  transition: width 2s;
  background: #353535;
}
.main-container:hover .line {
  width: 125px;
}
.set-right {
  flex-direction: row-reverse;
}
.text-container {
  width: 100%;
  display: block;
  max-width: 600px;
  margin: 10px;
  @include breakpoint(mobile) {
    max-width: 95vw;
  }
}
.center-on-small {
  @include breakpoint(mobile) {
    justify-content: center;
    align-items: center;
  }
}
img {
  transition: all 200ms ease-out;
}
.grey {
  filter: grayscale(100%);
}
.clear {
  filter: grayscale(0%);
}
</style>
